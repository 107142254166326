<template>
	<div class="form-group">
		<div class="input-group account-wrapper">
			<MaterialInput
				v-model="promoValue"
				:label="$t('deposit.promocode')"
				type="text"
				:error="formError"
				@input="changeFormPromoValue"
			/>
		</div>
	</div>
</template>

<script>
import MaterialInput from '@/components/material-input/MaterialInput';

export default {
	name: 'Promocode',
	components: { MaterialInput },
	props: {
		formPromoValue: String,
		formError: String,
	},
	data() {
		return { promoValue: this.formPromoValue };
	},
	methods: {
		changeFormPromoValue(value) {
			console.log('change formpromo value called', value);
			this.$emit('set-promo-value', value);
		},
	},
};
</script>
